import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import HomeHeader from '../../components/theme/HomeHeader'
import RenderBlock from '../../components/blocks/RenderBlock'

const IndexPage = ({ data }) => (
  <Layout
    meta={data.startPage.meta}
    urls={data.startPage.urls}
    locale='zh-hans'
  >
    <HomeHeader
      frontHeaderText={data.startPage.title_html}
      slides={data.startPage.slides}
      urls={data.startPage.urls}
      locale='zh-hans'
    />

    <RenderBlock blocks={data.startPage.blocks} />

    <Footer />
  </Layout>
)

export const query = graphql`
query {
  startPage(guid: { eq: "54319571-6668-471c-bc49-71b26b4b0ddf" }, locale: { eq: "zh-hans" }) {
    title_html
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    slides {
      name
      link
      image_url
      mobile_image_url
    }
    blocks {
      __typename
      ...productCategoriesBlockFragment
      ...imageLeftTextRightBlockFragment
      ...promotionBlockFragment
    }
    meta {
      description,
      keywords
      title
    }
  }
}
`

export default IndexPage
