/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/core'
import {FormattedMessage} from 'react-intl';
import Imgix, {  Picture, Source } from "react-imgix";
import {Link} from 'gatsby'
import Background from 'react-imgix'

import sendEvent from '../../../helpers/sendAnalytics'
import fixImageUrl from '../../../helpers/fixImageUrl'

import Header from '../Header'
import Button from '../../common/Button'
import WhereToBuy from '../../common/WhereToBuy'

import Line from '../../../assets/images/line.svg'
import walmart from '../../../assets/images/walmartlogo.png'
import amazon from '../../../assets/images/amazonlogo.png'
import petSlide from '../../../assets/pets-images/homepage-slide@2x.jpg'
import LogoImg from '../../../assets/pets-images/logo_pets@2x.png'
import LogoImgFR from '../../../assets/pets-images/logo_pets@2x_fr.png'

import hSlide1 from '../../../assets/images/Royale1.jpg'
import hSlide2 from '../../../assets/images/homepage-slide2@2x.jpg'


import NewSlide from '../../../assets/images/gufs_slide@2x.png'
import NewSlide2 from '../../../assets/images/gufs_slide_fr@2x.png'

const FrontSlider = styled.div`
  ${tw`flex`}
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const FrontLeftSide = styled.div`
  width: 35%;
  height: calc(100vh - 50px) !important;
  min-height: 620px;
`

const FrontRigtSide = styled.div`
  ${tw`flex-no-grow flex-shrink`}
  height: calc(100vh - 50px) !important;
  width: 65%;
`

const FrontLeftWrapper = styled.div`
  ${tw`flex flex-col justify-center`}
  max-width: 386px;
  height: calc(100vh - 50px);
  margin-right: auto;
  margin-left: auto;
`

const FrontHeaderText = styled.h1`
  ${tw`font-montserrat my-0 text-6xl font-light text-black`}
  line-height: 60px;
  font-size:3rem;
  p {
    margin: 0;
  }

  sup {
    ${tw`text-xl`}
    vertical-align: top;
    top: 15px;
  }
`

const FrontHeaderTextMobile = styled.h1`
  ${tw`font-montserrat my-0 text-4xl text-black font-light mb-6`}
  line-height: 1;
  height 120px;
  display:block;
  padding-top: 20px;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;

  p {
    margin: 0;
  }

  sup {
    ${tw`text-lg`}
    vertical-align: top;
    top: 12px;
  }
`

const SlideList = styled.ul`
  ${tw`list-reset`}
  margin-top: 38px;
  margin-bottom: 53px;
  padding-left: 0px;
`

const SlideItem = styled.li`
  ${tw`font-raleway text-base`}
  margin-bottom: 20px;
  -webkit-transition: padding-left 400ms cubic-bezier(.645, .045, .355, 1);
  transition: padding-left 400ms cubic-bezier(.645, .045, .355, 1);
  color: ${props => props.active ? '#4D8DCD' : '#7a8184'};
  font-weight: ${props => props.active ? '900' : '300'};
  line-height: 19px;

  padding-left: ${props => props.active ? '20px' : ''};
  background-image: ${props => props.active ? `url(${Line})` : ''};
  background-position: ${props => props.active ? '0px 50%' : ''};
  background-size: ${props => props.active ? '6px' : ''};
  background-repeat: ${props => props.active ? 'no-repeat' : ''};

  p {
    margin: 0px;
  }
`

const FrontRightSideImage = styled.div`
  ${tw`w-full h-full relative`}
  height: calc(100vh - 120px);
  min-height: 100%;
  position:relative;
  & > img.slide-image {
    width: 100%;
    object-fit: cover;
    min-height: calc(100vh - 120px);
    height: calc(100vh - 120px);
  }
  & > div {
    width: 100%;
    height: 100%;
  }
`

const MobileHome = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }
`

const MobileHeaderBox = styled.div`
  margin-top: 20px;
  position: relative;
`

const MobileButtonWrapper = styled.div`
  text-align: center;
  margin-top: -30px;
`

//  const MobileButton = styled(Button)`
//  margin: -20px 0px 0px 0px;
//  position: relative;
//  z-index: 1;
//  `

const MobileImageShow = styled.div`
  display: none;

  @media screen and (max-width: 850px) {
    display: block;
    img {
      width: 100%;
    }
    & > .gatsby-image-wrapper {
      height: 370px;
      width: 100%;
    }
  }
`

const TabletImageShow = styled.div`
  display: none;

  @media screen and (min-width: 850px) {
    display: block;

    & > .gatsby-image-wrapper {
      height: 600px;
      width: 100%;
    }
  }
`

const SlideRight = styled.img`
  width: 100%;
  height: auto;
`

const PetsLogo = styled.img`
  position: absolute;
  left: -75px;
  top: 25%;
  width: 300px !important;
  @media(max-width: 850px) {
    width: 150px !important;
    left: 0;
    top: 15%;
  }
`

const LinkTo = styled.a`
    div {
        width: 100%;
        height: 100%;
    }
`

class HomeHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      slide: 0,
      homeScreen:false
    }
  }

  componentDidMount() {
    const { slides } = this.props;

    this.timerHandle = setInterval(() => {
      const { slide } = this.state
      if ((slide + 1) === 3) {
        this.setState({
          slide: 0
        })
      } else {
        this.setState((prevState) => ({
          slide: prevState.slide + 1
        }))
      }
    }, 5000)
  }

  componentWillMount(){

  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
      this.timerHandle = 0;
    }
  }

  render() {
    const { frontHeaderText, slides, urls, locale } = this.props;
    const { slide, homeScreen } = this.state;
    const Headers = [
      locale === 'fr' ? 'Bianca se joint à l’équipe ROYALE<sup>MD</sup>'  : 'Bianca Joins Team ROYALE<sup>®</sup>',
      locale === 'fr' ?
        'Projet un abri pour chaque ami'
        :
        'Home for Every Pet Project',
      frontHeaderText
    ]
    const Texts = [
      {
        text:locale == 'fr' ?
          'ROYALE<sup>MD</sup> est fière de s’associer à l’athlète canadienne de l’année, Bianca Andreescu. Cliquez ci-dessous pour vous renseigner davantage sur ce partenariat fièRRRement canadien.'
          :
          'ROYALE<sup>®</sup> is proud to partner with Canada\'s Athlete of the Year, Bianca Andreescu. Click below to find out more about this PURR-oudly Canadian partnership.',
        url:locale === 'fr' ? "fr/fierrrementcanadienne":"purr-oudlycanadian",
        buttonText:locale === 'fr' ? 'En savoir plus':'Learn More'
      },
      {
        text:locale === 'fr' ?
          'En partenariat avec des refuges pour animaux au Canada, nous contribuons à financer les soins qu’ils dispensent et à sensibiliser la population pour que les animaux recueillis puissent trouver un foyer aimant et permanent dans des familles canadiennes. '
          :
          'We’re partnering with animal shelters across Canada, helping raise awareness and fund in-shelter care so pets can find loving, forever homes with Canadian families. ',
        url:locale == 'fr' ? "fr/ami":"pets",
        buttonText:locale === 'fr' ? 'Faites la connaissance de ces animaux':'Meet the Pets'
      },
      {
        text:null,
        url:locale === 'fr' ? "fr/produits":"products",
        buttonText:locale === 'fr' ? 'Découvrir les produits':'View products'
      },
    ]

    // override the default slider slides, jsut remove this to revert back
    slides[0].image_url = fixImageUrl('/globalassets/promotion/bianca/bianca.jpg')
    slides[0].mobile_image_url = fixImageUrl('/globalassets/promotion/bianca/bianca.jpg')
    slides[1].image_url = fixImageUrl('/globalassets/homepage-slide2x.jpg')
    slides[1].mobile_image_url = fixImageUrl('/globalassets/homepage-slide2x.jpg')
    slides[2].image_url = fixImageUrl('/globalassets/homepage-slide22x.jpg')
    slides[2].mobile_image_url = fixImageUrl('/globalassets/homepage-slide22x.jpg')

    return (
      <Header
        locale={locale}
        urls={urls}
      >
        <FrontSlider>
          <FrontLeftSide>
            <FrontLeftWrapper>
                <FrontHeaderText dangerouslySetInnerHTML={{ __html: Headers[slide] }} />
                { slide == 2 ?
                  <>
                    <SlideList>
                      {slides.map((item, i) =>
                        <SlideItem active={false} key={item.name} dangerouslySetInnerHTML={{ __html: item.name }} />
                      )}
                    </SlideList>
                    <Button
                      to="08421dc2-480c-4c99-b6d5-834990b6fe7f"
                      width='270px'
                      css={css`${tw`mb-4`}`}
                      onClick={() =>
                        sendEvent({
                          ga:{category:'Engagement',
                            action:'Click',
                            label:(locale === 'en') ? 'Homepage_View_Products' : 'Homepage_View_Products_FR'
                          }})
                        }
                    >
                      <FormattedMessage
                        id="view_products"
                      />
                    </Button>
                    <WhereToBuy
                      buy_online={[
                        {
                          image: amazon,
                          link: 'https://www.amazon.ca/stores/node/7775965011'
                        },
                        {
                          image: walmart,
                          link: 'https://www.walmart.ca/en/royale/n-1019979'
                        }
                      ]}
                      front
                    />
                  </>
                  :
                  <>
                    <div style={{
                        marginTop:'50px',
                        marginBottom: '50px',
                        fontFamily:'Montserrat',
                        color:'#6b6a6a',
                        lineHeight:'22pt',
                        fontWeight:'300'
                      }} dangerouslySetInnerHTML={{ __html: Texts[slide].text }} />
                    <Button
                      to={Texts[slide].url}
                      width='270px'
                      css={css`${tw`mb-4`}`}
                      convert={false}
                    >
                      {Texts[slide].buttonText}
                    </Button>
                  </>

                }
            </FrontLeftWrapper>
          </FrontLeftSide>
          <FrontRigtSide>
            <FrontRightSideImage>
              <Background src={slides[slide].image_url} className="slide-image" imgixParams={{ crop: 'faces' }} />
              { slide == 1 ?
                <PetsLogo src={locale == 'fr' ? LogoImgFR:LogoImg} />:null
              }
            </FrontRightSideImage>
          </FrontRigtSide>
        </FrontSlider>
        <MobileHome home>
          <FrontHeaderTextMobile dangerouslySetInnerHTML={{ __html: Headers[slide] }} />
          <MobileHeaderBox>
            <MobileImageShow>
              <Picture>
                <Source
                  src={slides[slide].mobile_image_url}
                  imgixParams={{
                    fit: 'crop'
                  }}
                  width={1024}
                  htmlAttributes={{ media: "(min-width: 768px)" }}
                />
                <Source
                  src={slides[slide].mobile_image_url}
                  imgixParams={{
                    fit: 'crop'
                  }}
                  width={700}
                  htmlAttributes={{ media: "(min-width: 320px)" }}
                />
                <Imgix src={slides[slide].mobile_image_url} width={360} />
              </Picture>
              { slide == 1 ?
                <PetsLogo src={locale == 'fr' ? LogoImgFR:LogoImg} />:null
              }
            </MobileImageShow>
            <TabletImageShow>
              <Imgix src={slides[slide].image_url} width={1200} />
              { slide == 1 ?
                <PetsLogo src={locale == 'fr' ? LogoImgFR:LogoImg} />:null
              }
            </TabletImageShow>
            <MobileButtonWrapper>
                <Link
                  className="blue-button"
                  style={{padding:'13px 30px'}}
                  to={Texts[slide].url}>
                  {Texts[slide].buttonText}
                </Link>
            </MobileButtonWrapper>
          </MobileHeaderBox>
        </MobileHome>
      </Header>
    )
  }
}

HomeHeader.propTypes = {
  // Text that shows at the top
  frontHeaderText: PropTypes.string,
  // Any additional menu elements (used for homepage)
  slides: PropTypes.arrayOf(PropTypes.shape({
    // Name of the slide
    name: PropTypes.string.isRequired,
    // Slide image
    image_url: PropTypes.string.isRequired,
  })).isRequired,
  // Used to change languages
  urls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    fr: PropTypes.string.isRequired,
    zh_hans: PropTypes.string.isRequired,
    zh_hant: PropTypes.string.isRequired,
  }).isRequired,
  // used to determine current langage
  locale: PropTypes.string
}

HomeHeader.defaultProps = {
  frontHeaderText: '',
  switchPath: '',
  locale: 'en'
}

export default HomeHeader
