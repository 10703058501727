import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Modal from 'react-modal';
import Imgix from "react-imgix";
import {injectIntl, FormattedMessage} from 'react-intl';
import sendEvent from '../../../helpers/sendAnalytics';

import { Flex, Box } from '../Grid'
import Button from '../Button'
import Icon from '../Icon'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0px',
    borderRadius: '0px',
    padding: '70px 100px',
    zIndex: '1000'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.30)'
  }
};

const WhereToBuyWrapper = styled.div``

const WhereToBuyHeader = styled.h2`
  ${tw`font-montserrat font-light text-5xl text-blog-text m-0`}

  p {
    margin: 0px;
  }

  @media screen and (max-width: 40em) {
    ${tw`text-3xl`}
  }
`

const WhereToBuyImage = styled(Imgix)`
  min-width: 239px;

  img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 40em) {
    min-width: auto;
    width: auto;
    height: 60px;
    margin-bottom: 10px;
  }
`

const WhereToBuyImageDefault = styled.img`
  min-width: 239px;

  @media screen and (max-width: 40em) {
    min-width: auto;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    max-width: 200px;
  }
`

const WhereToBuyBox = styled(Box)`
  ${tw`text-center px-10`}

  @media screen and (max-width: 40em) {
    ${tw`p-0 mb-8`}
  }
`

const CloseButtonWrapper = styled.div`
  ${tw`absolute cursor-pointer text-black`}
  right: 50px;
  top: 50px;
`

const WhereToBuyImageWrapper = styled.div`
  ${tw`mt-16`}
  height: 120px;

  @media screen and (max-width: 40em) {
    ${tw`h-auto mt-5`}
  }
`

Modal.setAppElement('#___gatsby')

class WhereToBuy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleBuyOnline = this.handleBuyOnline.bind(this);
  }

  openModal() {
    const { guid, front, intl } = this.props

    this.setState({ modalIsOpen: true });

    let appnexus = ''
    switch (guid) {
      // BATHROOM TISSUE
      case '9de33f1a-d015-4a75-aba7-07b164bcdad4':
      case 'ea3bb2a6-0a1f-4ddf-9524-bb80972676cb':
      case 'ac08b9ef-e3a6-4638-8644-64d746441e92':
        appnexus = 'https://secure.adnxs.com/px?id=1066979&seg=16533686&t=2';
        break;
      // PAPER TOWEL
      case 'ad0d0b54-9e32-4f22-903b-310640dd2bf2':
      case 'fff721e8-fdad-452d-8d59-bc20ef3527b1':
      case '6368dbae-5f37-4adc-9ff1-41dceff436f0':
        appnexus = 'https://secure.adnxs.com/px?id=1066980&seg=16533687&t=2';
        break;
      // FACIAL TISSUE
      case '0868c481-fada-4278-bf85-9298e6679379':
      case '3a0cddec-8bb0-4bc0-b18a-2d817a355376':
      case 'b7b4a2df-3de4-468b-b9d8-67ebec6171ec':
      case 'a3805fe3-4f1f-4f36-b67c-d507635224fc':
        appnexus = 'https://secure.adnxs.com/px?id=1066970&seg=16533568&t=2';
        break;
      default:
    }

    if (front) {
      appnexus = 'https://secure.adnxs.com/px?id=1067010&seg=16534037&t=2'
    } else {
      sendEvent({
        ga: {
          category: 'Buy_Online',
          action: 'Click',
          label: (intl.locale === 'en') ? 'Buy_Online_Product_Page' : 'Buy_Online_Product_Page_FR'
        }
      })
    }

    // EVENT: BuyOnline
    sendEvent({
      ga: {
        category: 'Buy_Online',
        action: 'Click',
        label: 'Buy_Online_Popup'
      },
      gtag: {
        send_to: 'AW-951001886/EpWZCKeI-5IBEJ7GvMUD'
      },
      fb: 'Lead',
      appnexus
    })

    sendEvent({
      ga: {
        category: 'Buy_Online',
        action: 'Click',
        label: (intl.locale === 'en') ? 'Buy_Online_Homepage' : 'Buy_Online_Homepage_FR'
      }
    })
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleBuyOnline(link) {
    const { guid, intl } = this.props

    let appnexus = '';
    let ga = null

    if (link.includes('walmart')) {
      switch (guid) {
        // BATHROOM TISSUE
        case '9de33f1a-d015-4a75-aba7-07b164bcdad4':
        case 'ea3bb2a6-0a1f-4ddf-9524-bb80972676cb':
        case 'ac08b9ef-e3a6-4638-8644-64d746441e92':
          appnexus = 'https://secure.adnxs.com/px?id=1066994&seg=16533838&t=2';
          break;
        // PAPER TOWEL
        case 'ad0d0b54-9e32-4f22-903b-310640dd2bf2':
        case 'fff721e8-fdad-452d-8d59-bc20ef3527b1':
        case '6368dbae-5f37-4adc-9ff1-41dceff436f0':
          appnexus = 'https://secure.adnxs.com/px?id=1066996&seg=16533890&t=2';
          break;
        // FACIAL TISSUE
        case '0868c481-fada-4278-bf85-9298e6679379':
        case '3a0cddec-8bb0-4bc0-b18a-2d817a355376':
        case 'b7b4a2df-3de4-468b-b9d8-67ebec6171ec':
        case 'a3805fe3-4f1f-4f36-b67c-d507635224fc':
          appnexus = 'https://secure.adnxs.com/px?id=1066999&seg=16533977&t=2';
          break;
        default:
      }

      ga = {
        category: 'Buy_Online',
        action: 'Click',
        label: (intl.locale === 'en') ? 'Walmart_Click' : 'Walmart_Click_FR'
      }
    } else {
      switch (guid) {
        // BATHROOM TISSUE
        case '9de33f1a-d015-4a75-aba7-07b164bcdad4':
        case 'ea3bb2a6-0a1f-4ddf-9524-bb80972676cb':
        case 'ac08b9ef-e3a6-4638-8644-64d746441e92':
          appnexus = 'https://secure.adnxs.com/px?id=1066995&seg=16533864&t=2';
          break;
        // PAPER TOWEL
        case 'ad0d0b54-9e32-4f22-903b-310640dd2bf2':
        case 'fff721e8-fdad-452d-8d59-bc20ef3527b1':
        case '6368dbae-5f37-4adc-9ff1-41dceff436f0':
          appnexus = 'https://secure.adnxs.com/px?id=1066997&seg=16533952&t=2';
          break;
        // FACIAL TISSUE
        case '0868c481-fada-4278-bf85-9298e6679379':
        case '3a0cddec-8bb0-4bc0-b18a-2d817a355376':
        case 'b7b4a2df-3de4-468b-b9d8-67ebec6171ec':
        case 'a3805fe3-4f1f-4f36-b67c-d507635224fc':
          appnexus = 'https://secure.adnxs.com/px?id=1067002&seg=16533989&t=2';
          break;
        default:
      }

      ga = {
        category: 'Buy_Online',
        action: 'Click',
        label: (intl.locale === 'en') ? 'Amazon_Click' : 'Amazon_Click_FR'
      }
    }

    if (appnexus) {
      sendEvent({
        appnexus,
        ga
      })
    }
  }

  render() {
    const { modalIsOpen } = this.state
    const { buy_online, front, className } = this.props

    return (
      <React.Fragment>
        {front ? <Button onClick={this.openModal} outline width='270px'><FormattedMessage id='buy_online' /></Button> : <Button onClick={this.openModal} className={className}><FormattedMessage id='buy_online' /></Button> }
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          aria={{
            labelledby: "heading"
          }}
          closeTimeoutMS={250}
        >
          <WhereToBuyWrapper>
            <CloseButtonWrapper onClick={this.closeModal}>
              <Icon icon="close" width="16" />
            </CloseButtonWrapper>
            <WhereToBuyHeader id="heading">
              <FormattedMessage id='select_retailers' />
            </WhereToBuyHeader>
            <Flex justifyContent="center" flexWrap="wrap">
              {buy_online.map(item => (
                item.link ? (
                  <WhereToBuyBox key={item.link}>
                    <WhereToBuyImageWrapper>
                      {item.image_url
                        ?
                          <WhereToBuyImage src={item.image_url} width="240" alt='' />
                        :
                          <WhereToBuyImageDefault src={item.image} alt='' />
                      }

                    </WhereToBuyImageWrapper>

                    <Button
                      to={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => this.handleBuyOnline(item.link)}
                    >
                      <FormattedMessage id='buy_online' />
                    </Button>
                  </WhereToBuyBox>
                  ) : null
              ))}
            </Flex>
          </WhereToBuyWrapper>
        </Modal>
      </React.Fragment>
    );
  }
}

WhereToBuy.propTypes = {
  buy_online: PropTypes.shape([
    PropTypes.shape({
      image: PropTypes.oneOfType([
        PropTypes.shape({
          base64: PropTypes.string,
          width: PropTypes.string,
          height: PropTypes.string,
          src: PropTypes.string,
          srcSet: PropTypes.string
        }),
        PropTypes.string
      ]),
      link: PropTypes.string
    })
  ]).isRequired,
  front: PropTypes.bool,
  guid: PropTypes.string
}

WhereToBuy.defaultProps = {
  front: false,
  guid: '',
}

export default injectIntl(WhereToBuy)
